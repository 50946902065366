import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

type IStylesParams = {
  primaryButtonBGColor: StyleParamType.Color;
  primaryButtonTextFont: StyleParamType.Font;
  primaryButtonTextColor: StyleParamType.Color;
  primaryButtonBorderColor: StyleParamType.Color;
  primaryButtonBorderWidth: StyleParamType.Number;
  primaryButtonBorderRadius: StyleParamType.Number;
  secondaryButtonBGColor: StyleParamType.Color;
  secondaryButtonTextFont: StyleParamType.Font;
  secondaryButtonTextColor: StyleParamType.Color;
  secondaryButtonBorderColor: StyleParamType.Color;
  secondaryButtonBorderWidth: StyleParamType.Number;
  secondaryButtonBorderRadius: StyleParamType.Number;
};

export const buttonStylesParams = createStylesParams<IStylesParams>({
  primaryButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  primaryButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  primaryButtonBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  primaryButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },
  primaryButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  primaryButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  secondaryButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  secondaryButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondaryButtonBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },
  secondaryButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  secondaryButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  secondaryButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
