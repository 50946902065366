import type { SavedPaymentMethodWithMeta } from '@appTypes';

export const removeId = (
  paymentMethodsIds: string[],
  paymentMethodId: string,
) => paymentMethodsIds.filter((id) => id !== paymentMethodId);

export const removeById = (
  paymentMethods: SavedPaymentMethodWithMeta[],
  id: string,
) => paymentMethods.filter((method) => method.id !== id);

export const setPrimary = (
  paymentMethods: SavedPaymentMethodWithMeta[],
  id: string,
) => paymentMethods.map((method) => (method.primary = method.id === id));
