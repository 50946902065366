import {
  createStylesParams,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

type IStylesParams = {
  titleDividerColor: StyleParamType.Color;
  titleDividerWidth: StyleParamType.Number;
  itemsDividerColor: StyleParamType.Color;
  itemsDividerWidth: StyleParamType.Number;
};

export const dividerStylesParams = createStylesParams<IStylesParams>({
  titleDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  titleDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  itemsDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  itemsDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
});
