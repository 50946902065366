import { createSlice } from '@reduxjs/toolkit';
import type { SavedPaymentMethodWithMeta } from '@appTypes';
import { concatWithDefaultMetaTypes } from '@utils/paymentMethodMapper';
import {
  fetchPaymentMethodTypes,
  fetchSavedPaymentMethods,
  markSavedPaymentMethodPrimary,
  removeSavedPaymentMethod,
} from './actions';
import { removeId, removeById, setPrimary } from './utils';

export type State = {
  isFetched: boolean;
  isPaymentMethodTypesFetched: boolean;
  alreadyFetchedOnce?: boolean;
  deletedIDsQueue: string[];
  managedPaymentMethodId?: string;
  savedPaymentMethods?: SavedPaymentMethodWithMeta[];
};

export const initialState: State = {
  isFetched: false,
  isPaymentMethodTypesFetched: false,
  deletedIDsQueue: [],
};

const savedPaymentMethodsSlice = createSlice({
  name: 'savedPaymentMethods',
  initialState,
  reducers: {
    setManagedMethodId: (state, action) => {
      state.managedPaymentMethodId = action.payload;
    },
    setMethodPrimary: (state, action) => {
      if (state.savedPaymentMethods) {
        setPrimary(state.savedPaymentMethods, action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(markSavedPaymentMethodPrimary.fulfilled, (state, action) => {
        const { paymentMethodId } = action.meta.arg;

        if (state.savedPaymentMethods) {
          setPrimary(state.savedPaymentMethods, paymentMethodId);
        }
      })

      .addCase(fetchSavedPaymentMethods.pending, (state) => {
        state.isFetched = false;
      })
      .addCase(fetchSavedPaymentMethods.rejected, (state) => {
        state.isFetched = true;
        state.alreadyFetchedOnce = true;
      })
      .addCase(fetchSavedPaymentMethods.fulfilled, (state, action) => {
        state.savedPaymentMethods = action.payload;
        state.isFetched = true;
        state.alreadyFetchedOnce = true;
      })

      .addCase(fetchPaymentMethodTypes.pending, (state) => {
        state.isPaymentMethodTypesFetched = false;
      })
      .addCase(fetchPaymentMethodTypes.rejected, (state) => {
        state.savedPaymentMethods = concatWithDefaultMetaTypes(
          state.savedPaymentMethods,
        );
        state.isPaymentMethodTypesFetched = true;
      })
      .addCase(fetchPaymentMethodTypes.fulfilled, (state, action) => {
        state.savedPaymentMethods = action.payload;
        state.isPaymentMethodTypesFetched = true;
      })

      .addCase(removeSavedPaymentMethod.pending, (state, action) => {
        state.deletedIDsQueue.push(action.meta.arg.paymentMethodId);
      })
      .addCase(removeSavedPaymentMethod.rejected, (state, action) => {
        state.deletedIDsQueue = removeId(
          state.deletedIDsQueue,
          action.meta.arg.paymentMethodId,
        );
      })
      .addCase(removeSavedPaymentMethod.fulfilled, (state, action) => {
        const { paymentMethodId } = action.meta.arg;
        const { savedPaymentMethods } = state;

        if (savedPaymentMethods) {
          state.savedPaymentMethods = removeById(
            savedPaymentMethods,
            paymentMethodId,
          );
        }

        state.deletedIDsQueue = removeId(
          state.deletedIDsQueue,
          action.meta.arg.paymentMethodId,
        );
      });
  },
});

export const {
  reducer: savedPaymentMethodsReducer,
  actions: savedPaymentMethodsActions,
} = savedPaymentMethodsSlice;
