import React, { type FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { IconButton } from 'wix-ui-tpa/cssVars';
import { st, classes } from './CloseButton.st.css';

type CloseButtonProps = {
  onClick: () => void;
};

export const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <IconButton
      upgrade
      icon={<Close aria-hidden="true" />}
      aria-label={t('app.modal.close-button.aria-label')}
      className={st(classes.root)}
      onClick={onClick}
    />
  );
};
