import React, { type FC } from 'react';
import type { ButtonProps as TPAButtonProps } from 'wix-ui-tpa/cssVars';
import { Button as TPAButton, Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { DataHooks } from './DataHooks';
import { st, classes } from './Button.st.css';

export type ButtonProps = TPAButtonProps & {
  wiringVariant?: 'primary' | 'secondary';
  isLoading?: boolean;
};

export const Button: FC<ButtonProps> = (props) => {
  const {
    isLoading,
    wiringVariant,
    children,
    prefixIcon,
    className,
    onClick,
    ...rest
  } = props;

  return (
    <TPAButton
      {...rest}
      upgrade
      prefixIcon={
        isLoading ? (
          <Spinner
            type={SpinnerTypes.slim}
            className={st(classes.spinner)}
            data-hook={DataHooks.ProgressIndicator}
          />
        ) : (
          prefixIcon
        )
      }
      className={st(classes.root, { isLoading, wiringVariant }, className)}
      onClick={isLoading ? undefined : onClick}
    >
      {children}
    </TPAButton>
  );
};
