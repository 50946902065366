import React, { type FC } from 'react';
import type { InputDialogProps } from 'wix-ui-tpa/cssVars';
import { InputDialog as TPAInputDialog } from 'wix-ui-tpa/cssVars';
import { useScrollControl } from './ModalContext';
import { classes, st } from './InputDialog.st.css';

export const InputDialog: FC<InputDialogProps> = (props) => {
  const { isOpen, className } = props;

  useScrollControl(isOpen);

  return (
    <TPAInputDialog {...props} className={st(classes.root, {}, className)} />
  );
};
