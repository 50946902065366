import { createAsyncThunk } from '@reduxjs/toolkit';
import { Experiment, Interaction } from '@constants';
import { BiToastType } from '@services/BiLogger';
import { toastsActions } from '../../toast';
import type { ThunkApiConfig } from '../../store.types';
import type { MarkPrimaryThunk } from './types';

export const markSavedPaymentMethodPrimary = createAsyncThunk<
  void,
  MarkPrimaryThunk,
  ThunkApiConfig
>(
  'savedPaymentMethods/markPrimary',
  async ({ paymentMethodId }, { extra, dispatch, rejectWithValue }) => {
    const { flowAPI, savedPaymentMethodsApi } = extra;
    const {
      environment,
      errorHandler,
      errorMonitor,
      experiments,
      translations,
      fedops,
    } = flowAPI;

    try {
      if (environment.isViewer) {
        await savedPaymentMethodsApi.markAsPrimary(paymentMethodId);
      }

      fedops.interactionEnded(Interaction.MarkAsPrimary);
    } catch (error) {
      const resolvedError = errorHandler.getResolvedError(error);

      dispatch(
        toastsActions.showToast({
          text: experiments.enabled(Experiment.EnableErrorHandler)
            ? resolvedError.message
            : translations.t('app.toast.make-primary.error.text'),
          meta: { action: BiToastType.MarkPrimaryError, skin: 'error' },
        }),
      );

      if (error instanceof Error) {
        errorMonitor.captureException(error);
      }

      throw rejectWithValue({ error });
    }
  },
);
