import React, { type ReactNode, type FC, useCallback } from 'react';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import type { AppError } from '@appTypes';
import { Experiment } from '@constants';
import { Feedback } from '@components/Feedback';
import { Button } from '@components/Button';
import { DataHooks } from './DataHooks';

type Props = {
  error?: AppError;
  children: ReactNode;
  onRetry: () => void;
};

export const ApplicationError: FC<Props> = ({ children, error, onRetry }) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const isErrorHandlerEnabled = experiments.enabled(
    Experiment.EnableErrorHandler,
  );
  const resolvedError = isErrorHandlerEnabled ? error?.resolvedError : error;

  const handleClick = useCallback(() => {
    if (isErrorHandlerEnabled) {
      error?.resolvedError?.action?.onClick?.();
    } else {
      onRetry();
    }
  }, [error?.resolvedError?.action, isErrorHandlerEnabled, onRetry]);

  if (!resolvedError) {
    return <>{children}</>;
  }

  const showAction = isErrorHandlerEnabled
    ? Boolean(error?.resolvedError?.action?.text) &&
      Boolean(error?.resolvedError?.action?.onClick)
    : true;
  const message =
    isErrorHandlerEnabled && error?.resolvedError?.message
      ? error.resolvedError.message
      : t('app.error-page.fetch-failed.description');
  const ctaText =
    isErrorHandlerEnabled && error?.resolvedError?.action?.text
      ? error.resolvedError.action.text
      : t('app.error-page.fetch-failed.cta.text');

  return (
    <Feedback
      title={t('app.error-page.fetch-failed.title')}
      subtitle={message}
      subtitleDataHook={DataHooks.ErrorText}
      actions={
        showAction && (
          <Button
            wiringVariant="primary"
            data-hook={DataHooks.ErrorCtaButton}
            onClick={handleClick}
          >
            {ctaText}
          </Button>
        )
      }
    />
  );
};
