import { Component } from 'react';
import type { ErrorInfo, ReactNode } from 'react';

type State = {
  hasError: boolean;
};

type Props = {
  fallback: ReactNode;
  children: ReactNode;
  onError: (error: unknown, info: ErrorInfo) => void;
};

export class UIErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, info: ErrorInfo) {
    this.props.onError(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
