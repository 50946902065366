import { badgeStylesParams } from '@settings/Tabs/Design/Badge';
import { bgBordersStylesParams } from '@settings/Tabs/Design/BGBorders';
import { buttonStylesParams } from '@settings/Tabs/Design/Button';
import { dividerStylesParams } from '@settings/Tabs/Design/Divider';
import { textStylesParams } from '@settings/Tabs/Design/Text';

export default {
  ...badgeStylesParams,
  ...bgBordersStylesParams,
  ...buttonStylesParams,
  ...dividerStylesParams,
  ...textStylesParams,
};
