import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import type { PayPalPaymentMethod } from '@appTypes';
import { DEFAULT_PM_TITLE, Experiment } from '@constants';

export const getPayPalTitle = (
  paymentMethod: PayPalPaymentMethod,
  t: TFunction,
  displayName: string | null | undefined,
  experiments?: Experiments,
): string => {
  const { email } = paymentMethod.paymentInfo;

  if (experiments?.enabled(Experiment.UseSitePaymentMethodTypes)) {
    return t('app.payment-method.paypal.title', {
      title:
        displayName === DEFAULT_PM_TITLE
          ? t('app.payment-method.paypal.default-title')
          : displayName,
      email,
    });
  }

  return t('app.payment-method.paypal.title', {
    title: displayName || t('app.payment-method.paypal.default-title'),
    email,
  });
};

export const getPayPalHolder = (
  paymentMethod: PayPalPaymentMethod,
  t: TFunction,
): string | undefined => {
  const { accountHolder } = paymentMethod.paymentInfo;

  if (accountHolder) {
    return t('app.payment-method.account-holder.label', { accountHolder });
  }
};
