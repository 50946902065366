import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  titleFont: StyleParamType.Font;
  titleColor: StyleParamType.Color;
  subtitleFont: StyleParamType.Font;
  subtitleColor: StyleParamType.Color;
  primaryTextFont: StyleParamType.Font;
  primaryTextColor: StyleParamType.Color;
  secondaryTextFont: StyleParamType.Font;
  secondaryTextColor: StyleParamType.Color;
  noItemsMessageFont: StyleParamType.Font;
  noItemsMessageColor: StyleParamType.Color;
  noItemsInfoFont: StyleParamType.Font;
  noItemsInfoColor: StyleParamType.Color;
};

export const textStylesParams = createStylesParams<IStylesParams>({
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) =>
      wixFontParam('Page-title', {
        size: params.isMobile ? 20 : 28,
      })(params),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  subtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  primaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  primaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  secondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  secondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  noItemsMessageFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 24,
    }),
  },
  noItemsMessageColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  noItemsInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  noItemsInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
