import React, { type FC, type ReactNode } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Divider, Spinner } from 'wix-ui-tpa/cssVars';
import { DataFocusId } from '@services/FocusManager';
import { Text } from '@components/Text';
import { DataHooks } from './DataHooks';
import { st, classes } from './Layout.st.css';

type LayoutProps = {
  title: string;
  subtitle: string;
  isLoading?: boolean;
  children: ReactNode;
  className?: string;
  dataHook?: string;
};

export const Layout: FC<LayoutProps> = (props) => {
  const { isLoading, title, subtitle, children, className, dataHook } = props;
  const { isMobile, isRTL } = useEnvironment();

  return (
    <section
      dir={isRTL ? 'rtl' : 'ltr'}
      className={st(classes.root, { isMobile }, className)}
      data-hook={dataHook}
    >
      <div className={st(classes.header)}>
        <Text
          tagName="h2"
          className={st(classes.title)}
          data-hook={DataHooks.Title}
        >
          {title}
        </Text>
        <Text tagName="p" className={st(classes.subtitle)}>
          {subtitle}
        </Text>
      </div>
      <Divider className={st(classes.divider)} />
      <div
        tabIndex={-1}
        className={st(classes.content)}
        data-focus-id={DataFocusId.WidgetContent}
      >
        {isLoading ? (
          <Spinner isCentered className={st(classes.spinner)} />
        ) : (
          children
        )}
      </div>
    </section>
  );
};
