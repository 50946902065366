export enum DemoView {
  List = 'List',
  EmptyState = 'EmptyState',
}

export enum SettingsTab {
  Main = 'Main',
  Text = 'Text',
  Design = 'Design',
}

export enum DesignSection {
  Text = 'Text',
  Button = 'Buttons',
  Badge = 'Badges',
  Divider = 'Dividers',
  BGBorders = 'BGBorders',
}

export const TabArticleId: Record<SettingsTab, string> = {
  [SettingsTab.Main]: '32f0bf68-5690-4c88-9aa7-ecf80556d643',
  [SettingsTab.Text]: 'd33f5ca1-91cc-4cbb-b538-a7906fe648eb',
  [SettingsTab.Design]: 'f9a049dc-d07d-4935-887f-2de1cd0d31cb',
};
