import React from 'react';
import { st, classes } from './Skeleton.st.css';

interface SkeletonProps {
  isTextLine?: boolean;
  className?: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  isTextLine = false,
  className,
}) => {
  return (
    <div className={st(classes.root, { textLine: isTextLine }, className)} />
  );
};
