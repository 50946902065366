import React, { type FC } from 'react';
import { Popover } from 'wix-ui-tpa/cssVars';
import type { MenuBoxProps } from './ManageMenu.types';
import { st, classes } from './MenuPopover.st.css';

export const MenuPopover: FC<MenuBoxProps> = (props) => {
  const { isOpen, isRTL, trigger, children, onClose, onEscPress } = props;

  return (
    <Popover
      showArrow={false}
      shown={isOpen}
      minWidth={280}
      placement={isRTL ? 'right-start' : 'left-start'}
      className={st(classes.root)}
      onClickOutside={onClose}
      onEscPress={onEscPress}
    >
      <Popover.Element>{trigger}</Popover.Element>
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );
};
