import { createContext, useContext, useEffect } from 'react';
import type { Modal } from '@constants';

export type OpenModalFn = (currentModal: Modal) => void;
export type CloseModalFn = () => void;

export type DisplayedModal = {
  currentModal?: Modal;
};

export type ModalActions = {
  openModal: OpenModalFn;
  closeModal: CloseModalFn;
};

export type ScrollActions = {
  blockScroll: () => void;
  unblockScroll: () => void;
};

export type ModalState = DisplayedModal & {
  modalActions: ModalActions;
};

export type ModalContextType = DisplayedModal & ModalActions & ScrollActions;

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType,
);

export const useModalContext = (): ModalContextType => useContext(ModalContext);

export const useScrollControl = (isEnabled = true): void => {
  const { blockScroll, unblockScroll } = useModalContext();

  useEffect(() => {
    if (isEnabled) {
      blockScroll();

      return () => unblockScroll();
    }
  }, [blockScroll, isEnabled, unblockScroll]);
};
