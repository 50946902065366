import { createContext, useContext } from 'react';
import type { WidgetBiLogger } from './BiLogger.types';

type BiLoggerContextType<T = unknown> = {
  biLogger?: T;
};

export const BiLoggerContext = createContext<BiLoggerContextType>({});

export const useBiLogger = <T = WidgetBiLogger>() =>
  useContext(BiLoggerContext) as BiLoggerContextType<T>;
