import { useCallback, useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import type { Option } from '@appTypes';
import { useBiLogger, type SettingsBiLogger } from '@services/BiLogger';
import { SettingsEventManager } from '@services/SettingsEventManager';
import { DemoView } from '@settings/constants';
import { appSettingsParams } from '../../settingsParams';

export const useDemoViewOptions = (): Option<DemoView>[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { value: DemoView.List, label: t('textTab.pageSettings.title') },
      { value: DemoView.EmptyState, label: t('designTab.withoutItems.label') },
    ],
    [t],
  );
};

export const useDemoView = (initialView?: DemoView) => {
  const settings = useSettings();
  const demoViewOptions = useDemoViewOptions();
  const { biLogger } = useBiLogger<SettingsBiLogger>();

  const handleUpdate = useCallback(
    (view: DemoView) => {
      settings.publishEvent(SettingsEventManager.Event.DemoViewChanged, view);
      settings.set(appSettingsParams.demoView, view);
    },
    [settings],
  );

  const changeDemoView = useCallback(
    (view: DemoView) => {
      handleUpdate(view);
      biLogger?.reportDesignTabClickOnTopBar({ tab: view });
    },
    [biLogger, handleUpdate],
  );

  const resetDemoView = useCallback(() => {
    if (initialView) {
      handleUpdate(initialView);
    }
  }, [initialView, handleUpdate]);

  return useMemo(
    () => ({
      demoView: settings.get(appSettingsParams.demoView),
      demoViewOptions,
      changeDemoView,
      resetDemoView,
    }),
    [changeDemoView, demoViewOptions, settings, resetDemoView],
  );
};
