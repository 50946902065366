import { createContext, useContext } from 'react';

export type AppIdentityParams = {
  appDefinitionId: string;
  appInstance: string;
  appInstanceId: string;
} & Partial<{
  metaSiteId: string;
  siteOwnerId: string;
  appSessionId: string;
  visitorId: string;
}>;

type AppContextType = AppIdentityParams & {
  viewMode: string;
  deviceType: string;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const useAppContext = (): AppContextType => useContext(AppContext);
