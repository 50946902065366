import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DisplayedToast } from '@components/Toast';

type State = DisplayedToast;
type ShowToastPayload = DisplayedToast['currentToast'];

const initialState: State = {
  currentToast: undefined,
};

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ShowToastPayload>) => {
      state.currentToast = action.payload;
    },
    closeToast: () => initialState,
  },
});

export const { reducer: toastsReducer, actions: toastsActions } = toastsSlice;
