import React, { type ReactNode, type FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { Text } from '@components/Text';
import { CloseButton } from '@components/CloseButton';
import { DataHooks } from './DataHooks';
import { st, classes } from './ActionsMenu.st.css';

type ActionsMenuItem = {
  text: string;
  withDivider?: boolean;
  onClick: (paymentMethodId: string) => void;
};

export type ActionsMenuProps = {
  id: string;
  title?: string;
  items: ActionsMenuItem[];
  onClose: () => void;
};

export const ActionsMenu: FC<ActionsMenuProps> = (props) => {
  const { id, title, items, onClose } = props;
  const { isMobile, isEditor } = useEnvironment();

  return (
    <ActionsMenuLayout
      /* Don’t focus on the first element in the Editor because there is no navigation, and hover/focus styles are not customized */
      focusedIndex={isEditor ? -1 : 0}
      className={st(classes.root, { isMobile })}
      data-hook={DataHooks.Root}
    >
      {title && (
        <li className={st(classes.header)} data-hook={DataHooks.Header}>
          <div className={st(classes.title)}>
            <Text wiring>{title}</Text>
          </div>
          <CloseButton onClick={onClose} />
        </li>
      )}
      {items.reduce<ReactNode[]>(
        (actions, { text, withDivider, onClick }, i) => {
          if (withDivider) {
            actions.push(<ActionsMenuLayout.Divider />);
          }
          actions.push(
            <ActionsMenuLayout.Item
              key={`${id}-${i}`}
              content={text}
              onClick={() => {
                onClick(id);
                onClose();
              }}
            />,
          );

          return actions;
        },
        [],
      )}
    </ActionsMenuLayout>
  );
};
