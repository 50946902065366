import { createSettingsParams } from '@wix/tpa-settings';
import { DemoView } from '@settings/constants';
import { textSettingsParams } from '@settings/Tabs/Text/settingsParams';

export type IAppSettingsParams = {
  demoView: DemoView;
};

export const appSettingsParams = createSettingsParams<IAppSettingsParams>({
  demoView: {
    getDefaultValue: () => DemoView.List,
  },
});

export default {
  ...appSettingsParams,
  ...textSettingsParams,
};
