import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DisplayedModal } from '@components/Modal';

type State = DisplayedModal;
type OpenModalPayload = DisplayedModal['currentModal'];

const initialState: State = {
  currentModal: undefined,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state.currentModal = action.payload;
    },
    closeModal: () => initialState,
  },
});

export const { reducer: modalsReducer, actions: modalsActions } = modalsSlice;
