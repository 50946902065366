import React, { type ReactNode, type FC } from 'react';
import {
  useTranslation,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { Divider } from 'wix-ui-tpa/cssVars';
import type { SavedPaymentMethodWithMeta } from '@appTypes';
import {
  getExpirationDate,
  getPaymentMethodHolder,
  getPaymentMethodTitle,
} from '@utils/paymentMethod';
import {
  PaymentMethod,
  type PaymentMethodHandlers,
} from '@components/PaymentMethod';
import { DataHooks } from './DataHooks';
import { st, classes } from './PaymentMethodsList.st.css';

type PaymentMethodsListProps = {
  items: SavedPaymentMethodWithMeta[];
  isPaymentMethodTypesFetched: boolean;
  deletedIDsQueue?: string[];
  actions?: ReactNode;
} & PaymentMethodHandlers;

export const PaymentMethodsList: FC<PaymentMethodsListProps> = (props) => {
  const {
    items,
    deletedIDsQueue,
    actions,
    isPaymentMethodTypesFetched,
    onRemove,
    onMarkPrimary,
  } = props;
  const { t } = useTranslation();
  const { isEditor, isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  return (
    <section className={st(classes.root)}>
      <ul data-hook={DataHooks.Root}>
        {items.map((method, i) => (
          <li
            key={method.id}
            tabIndex={-1}
            data-hook={DataHooks.Item(i)}
            data-focus-id={method.id}
          >
            <PaymentMethod
              id={method.id}
              isPrimary={method.primary}
              isManageOpened={isEditor && i === 1 && !isMobile}
              isManageDisabled={deletedIDsQueue?.includes(method.id)}
              title={getPaymentMethodTitle(method, t, experiments)}
              holder={getPaymentMethodHolder(method, t)}
              expirationDate={getExpirationDate(method)}
              image={method.image}
              onRemove={onRemove}
              onMarkPrimary={onMarkPrimary}
              isPaymentMethodTypesFetched={isPaymentMethodTypesFetched}
            />
            {(actions || i < items.length - 1) && (
              <Divider className={st(classes.divider)} />
            )}
          </li>
        ))}
      </ul>
      {actions && <div className={st(classes.actions)}>{actions}</div>}
    </section>
  );
};
