import { createContext, useContext } from 'react';

type ToastMeta = Partial<{
  skin: 'success' | 'error';
  action: string;
}>;

export type ShowToastFn = (text: string, meta?: ToastMeta) => void;
export type CloseToastFn = () => void;

export type DisplayedToast = {
  currentToast?: {
    text: string;
    meta?: ToastMeta;
  };
};

export type ToastActions = {
  showToast: ShowToastFn;
  closeToast: CloseToastFn;
};

export type ToastState = DisplayedToast & {
  toastActions: ToastActions;
};

export type ToastContextType = DisplayedToast & ToastActions;

export const ToastContext = createContext<ToastContextType>(
  {} as ToastContextType,
);

export const useToastContext = (): ToastContextType => useContext(ToastContext);
