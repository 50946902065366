import type { SavedPaymentMethodWithMeta } from '@appTypes';
import { getExpirationDate, isDateExpired } from '@utils/paymentMethod';
import { DemoView } from '@settings/constants';
import type { BiPaymentMethodData } from './BiLogger.types';
import { BiDemoView } from './BiLogger.types';

const biDemoViewMap: Record<DemoView, BiDemoView> = {
  [DemoView.List]: BiDemoView.List,
  [DemoView.EmptyState]: BiDemoView.EmptyState,
};

export const getBiDemoView = (demoView?: string): string | undefined => {
  if (demoView) {
    return biDemoViewMap[demoView as DemoView] || demoView;
  }
};

export const getBiPaymentMethodsList = (
  paymentMethods?: SavedPaymentMethodWithMeta[],
): string | undefined => {
  if (paymentMethods) {
    return paymentMethods
      .map((paymentMethod) => paymentMethod.paymentMethod.typeId)
      .join(',');
  }
};

export const mapToBiPaymentMethodData = (
  paymentMethod?: SavedPaymentMethodWithMeta,
): BiPaymentMethodData | undefined => {
  if (paymentMethod) {
    const expirationDate = getExpirationDate(paymentMethod);
    const isExpired = expirationDate && isDateExpired(expirationDate);

    return {
      isExpired,
      isPrimary: paymentMethod.primary,
      paymentMethod: paymentMethod.paymentMethod.typeId,
    };
  }
};
