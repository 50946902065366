import { CardBrand } from '@wix/ambassador-payments-saved-payment-methods-v1-saved-payment-method/types';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import type { PaymentMethodMeta } from '@appTypes';
import ccIconURI from '@assets/icons/creditCard.svg?url';
import { cardBrandIconBaseUrl, paymentMethodIconUrl } from '../app.config';

export type PaymentMethodBrand = CardBrand | PaymentMethod.PayPal;

// TODO PAY-34271
export const metaData: Record<PaymentMethodBrand, PaymentMethodMeta> = {
  [CardBrand.AMEX]: {
    displayName: 'Amex',
    image: {
      url: `${cardBrandIconBaseUrl}/amex.svg`,
    },
  },
  [CardBrand.MASTERCARD]: {
    displayName: 'Mastercard',
    image: {
      url: `${cardBrandIconBaseUrl}/mastercard.svg`,
    },
  },
  [CardBrand.MAESTRO]: {
    displayName: 'Maestro',
    image: {
      url: `${cardBrandIconBaseUrl}/maestro.svg`,
    },
  },
  [CardBrand.JCB]: {
    displayName: 'JCB',
    image: {
      url: `${cardBrandIconBaseUrl}/jcb.svg`,
    },
  },
  [CardBrand.VISA]: {
    displayName: 'Visa',
    image: {
      url: `${cardBrandIconBaseUrl}/visa.svg`,
    },
  },
  [CardBrand.DANKORT]: {
    displayName: 'Dankort',
    image: {
      url: `${cardBrandIconBaseUrl}/dankort.svg`,
    },
  },
  [CardBrand.DISCOVER]: {
    displayName: 'Discover',
    image: {
      url: `${cardBrandIconBaseUrl}/discover.svg`,
    },
  },
  [CardBrand.DINERS]: {
    displayName: 'Diners',
    image: {
      url: `${cardBrandIconBaseUrl}/diners.svg`,
    },
  },
  [CardBrand.ISRACARD]: {
    displayName: 'Isracard',
    image: {
      url: `${cardBrandIconBaseUrl}/isracard.svg`,
    },
  },
  [CardBrand.RUPAY]: {
    displayName: 'RuPay',
    image: {
      url: `${cardBrandIconBaseUrl}/rupay.svg`,
    },
  },
  [CardBrand.UNIONPAY]: {
    displayName: 'UnionPay',
    image: {
      url: `${cardBrandIconBaseUrl}/unionpay.svg`,
    },
  },
  [CardBrand.UNKNOWN_CARD_BRAND]: {
    // Default text for the credit card will be displayed in the UI
    displayName: '',
    image: {
      url: ccIconURI,
    },
  },
  [PaymentMethod.PayPal]: {
    displayName: 'PayPal',
    image: {
      url: paymentMethodIconUrl.payPal,
    },
  },
};

export const getPaymentMethodMetaData = (
  brand: PaymentMethodBrand = CardBrand.UNKNOWN_CARD_BRAND,
): PaymentMethodMeta =>
  metaData[brand] || metaData[CardBrand.UNKNOWN_CARD_BRAND];
