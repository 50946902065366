import React, { type FC } from 'react';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';
import { TextPriority } from 'wix-ui-tpa/cssVars';
import type { ExpirationDate } from '@appTypes';
import { formatExpirationDate, isDateExpired } from '@utils/paymentMethod';
import { Text } from '@components/Text';
import { st, classes } from './ExpiryDate.st.css';

type ExpiryDateProps = {
  value: ExpirationDate;
};

export const ExpiryDate: FC<ExpiryDateProps> = ({ value }) => {
  const { t } = useTranslation();
  const isExpired = isDateExpired(value);

  return (
    <Text wiring tagName="p" priority={TextPriority.secondary}>
      <Trans
        i18nKey="app.payment-method.expiration-date.label"
        values={{
          date: formatExpirationDate(value),
          expirationStatus: isExpired
            ? t('app.payment-method.status.expired')
            : null,
        }}
        components={{
          span: <span className={st(classes.root, { isExpired })} />,
        }}
      />
    </Text>
  );
};
