import React, { type FC } from 'react';
import { MobileDrawer } from 'wix-ui-tpa/cssVars';
import { useScrollControl } from '@components/Modal';
import type { MenuBoxProps } from './ManageMenu.types';

export const MenuDrawer: FC<MenuBoxProps> = (props) => {
  const { isOpen, trigger, children, onClose } = props;

  useScrollControl(isOpen);

  return (
    <div>
      {trigger}
      <MobileDrawer isOpen={isOpen} onRequestClose={onClose}>
        {children}
      </MobileDrawer>
    </div>
  );
};
