import type { ControllerFlowAPI, IWixWindow } from '@wix/yoshi-flow-editor';

export enum WarmupDataKey {
  SavedPaymentMethods = 'savedPaymentMethods',
  CanAddNewPaymentMethod = 'canAddNewPaymentMethod',
}

export class WarmupData {
  private flowApi: ControllerFlowAPI;
  private warmupData: IWixWindow['warmupData'];

  constructor(flowAPI: ControllerFlowAPI) {
    this.flowApi = flowAPI;
    this.warmupData = flowAPI.controllerConfig.wixCodeApi.window.warmupData;
  }

  isSSR() {
    return this.flowApi.environment.isSSR;
  }

  get<T>(key: string): T | undefined {
    if (!this.isSSR()) {
      return this.warmupData.get(key);
    }
  }

  set<T>(key: string, data: T) {
    if (this.isSSR()) {
      this.warmupData.set(key, data);
    }
  }
}
