import { createAsyncThunk } from '@reduxjs/toolkit';
import { Experiment, Interaction } from '@constants';
import { BiToastType } from '@services/BiLogger';
import { toastsActions } from '../../toast';
import { modalsActions } from '../../modal';
import type { ThunkApiConfig } from '../../store.types';
import { getLastUpdatedMethod } from '../selectors';
import {
  fetchPaymentMethodTypes,
  fetchSavedPaymentMethods,
  savedPaymentMethodsActions,
} from '..';
import type { RemoveThunk } from './types';

export const removeSavedPaymentMethod = createAsyncThunk<
  void,
  RemoveThunk,
  ThunkApiConfig
>(
  'savedPaymentMethods/remove',
  async (
    { paymentMethodId },
    { extra, dispatch, getState, rejectWithValue },
  ) => {
    const { flowAPI, savedPaymentMethodsApi } = extra;
    const {
      environment,
      experiments,
      errorHandler,
      errorMonitor,
      translations,
      fedops,
    } = flowAPI;

    try {
      const state = getState();

      if (environment.isViewer) {
        await savedPaymentMethodsApi.remove(paymentMethodId);

        const lastUpdatedMethod = getLastUpdatedMethod(paymentMethodId)(state);
        if (lastUpdatedMethod) {
          dispatch(
            savedPaymentMethodsActions.setMethodPrimary(lastUpdatedMethod.id),
          );
        }

        await dispatch(fetchSavedPaymentMethods());
        if (experiments.enabled(Experiment.UseSitePaymentMethodTypes)) {
          dispatch(fetchPaymentMethodTypes());
        }
      }

      fedops.interactionEnded(Interaction.RemovePaymentMethod);
      dispatch(modalsActions.closeModal());
      dispatch(
        toastsActions.showToast({
          text: translations.t('app.toast.payment-method-removed.text'),
          meta: { action: BiToastType.RemovedPaymentMethod },
        }),
      );
    } catch (error) {
      const resolvedError = errorHandler.getResolvedError(error);

      dispatch(
        toastsActions.showToast({
          text: experiments.enabled(Experiment.EnableErrorHandler)
            ? resolvedError.message
            : translations.t('app.toast.remove-payment-method.error.text'),
          meta: { action: BiToastType.RemoveError, skin: 'error' },
        }),
      );

      if (error instanceof Error) {
        errorMonitor.captureException(error);
      }

      throw rejectWithValue({ error });
    }
  },
);
