import { isStorybook } from './environment';

export const getPaymentsWidgetLocale = (language: string): string => {
  if (isStorybook()) {
    // If the "Show keys" option is selected in the list of locales
    if (language === 'cimode') {
      return 'en-US';
    }
  }

  return language;
};
