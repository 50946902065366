import type { FocusedElement } from './types';
import { DataFocusId } from './constants';

const createSelector = (elementId: string) => `[data-focus-id='${elementId}']`;

export const select = (elementId: string): FocusedElement =>
  document.querySelector(createSelector(elementId));

export const focus = (element: FocusedElement) => {
  if (element) {
    setTimeout(() => {
      element.focus();
    }, 5);
  }
};

export const getManageMenuId = (id: string) =>
  `${DataFocusId.ManageMenu}-${id}`;
