import type { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import type { createBiLogger } from './BiLogger';
import type { appSettingsLogger } from './loggers/settingsLogger';

export type WidgetBiLogger = ReturnType<typeof createBiLogger>;
export type SettingsBiLogger = ReturnType<typeof appSettingsLogger>;

export enum BiAddButtonPlace {
  List = 'list',
  EmptyState = 'empty_state',
}

export enum BiModalType {
  AddPaymentMethod = 'adding_pm',
  RemovePaymentMethod = 'removing_pm',
}

export enum BiModalCloseTrigger {
  ClickCancelButton = 'cancel',
  ClickXButton = 'click_close',
  Submit = 'submit',
}

export enum BiToastType {
  AddedPaymentMethod = 'added',
  RemovedPaymentMethod = 'removed',
  RemoveError = 'remove_error',
  MarkPrimaryError = 'mark_primary_error',
}

export enum BiDemoView {
  List = 'demo',
  EmptyState = 'emptyState',
}

export type BiPaymentMethodData = {
  isExpired?: boolean;
  isPrimary: boolean;
  paymentMethod: PaymentMethod;
};
