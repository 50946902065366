import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
} from '@wix/tpa-settings';

type IStylesParams = {
  bgColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  manageMenuBorderRadius: StyleParamType.Number;
};

export const bgBordersStylesParams = createStylesParams<IStylesParams>({
  bgColor: {
    key: 'backgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  manageMenuBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
