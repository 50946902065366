import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import type { CreditCardPaymentMethod, ExpirationDate } from '@appTypes';
import { DEFAULT_PM_TITLE, Experiment } from '@constants';

export const getCreditCardTitle = (
  paymentMethod: CreditCardPaymentMethod,
  t: TFunction,
  displayName?: string,
  experiments?: Experiments,
): string => {
  const { lastFourDigits } = paymentMethod.paymentInfo;

  if (experiments?.enabled(Experiment.UseSitePaymentMethodTypes)) {
    return t('app.payment-method.card.title', {
      title:
        displayName === DEFAULT_PM_TITLE
          ? t('app.payment-method.card.default-title')
          : displayName,
      lastFourDigits,
    });
  }

  return t('app.payment-method.card.title', {
    title: displayName || t('app.payment-method.card.default-title'),
    lastFourDigits,
  });
};

export const getCreditCardHolder = (
  paymentMethod: CreditCardPaymentMethod,
  t: TFunction,
): string | undefined => {
  const { cardholderName } = paymentMethod.paymentInfo;

  if (cardholderName) {
    return t('app.payment-method.cardholder.label', { cardholderName });
  }
};

export const getCreditCardExpirationDate = (
  paymentMethod: CreditCardPaymentMethod,
): ExpirationDate | undefined => {
  const { expirationMonth, expirationYear } = paymentMethod.paymentInfo;

  return { month: expirationMonth, year: expirationYear };
};
