import { useMemo, useCallback } from 'react';
import {
  useEnvironment,
  useTranslation,
  useFedopsLogger,
} from '@wix/yoshi-flow-editor';
import { Interaction } from '@constants';
import { useBiLogger, BiModalType } from '@services/BiLogger';
import { useFocusManager } from '@services/FocusManager';
import { isDateExpired } from '@utils/paymentMethod';
import type { ManageMenuProps } from '@components/ManageMenu';
import type { PaymentMethodProps } from './PaymentMethod.types';

type ManageItems = ManageMenuProps['items'];

export const useManageItems = (props: PaymentMethodProps): ManageItems => {
  const { isPrimary, expirationDate, onMarkPrimary, onRemove } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { biLogger } = useBiLogger();
  const { focusById } = useFocusManager();
  const fedops = useFedopsLogger();
  const isExpired = expirationDate && isDateExpired(expirationDate);

  const handleRemoveClick = useCallback(
    (savedPaymentMethodId: string) => {
      biLogger?.reportRemoveButtonClicked({ savedPaymentMethodId });
      onRemove(savedPaymentMethodId);

      fedops.interactionStarted(Interaction.LoadRemovalModal);
      biLogger?.reportModalLoadStarted({
        savedPaymentMethodId,
        modalType: BiModalType.RemovePaymentMethod,
      });
    },
    [biLogger, fedops, onRemove],
  );

  const handleSetPrimaryClick = useCallback(
    (savedPaymentMethodId: string) => {
      fedops.interactionStarted(Interaction.MarkAsPrimary);
      biLogger?.reportSetPrimaryClicked({ savedPaymentMethodId });
      onMarkPrimary(savedPaymentMethodId);
      focusById(savedPaymentMethodId);
    },
    [biLogger, fedops, focusById, onMarkPrimary],
  );

  return useMemo(() => {
    const removeItem: ManageItems[0] = {
      text: t('app.payment-method.manage-menu.remove-item.text'),
      onClick: handleRemoveClick,
    };

    if (isExpired) {
      return [removeItem];
    }

    const menuItems: ManageItems = [];

    if (!isPrimary) {
      menuItems.push({
        text: t('app.card.make-primary'),
        onClick: handleSetPrimaryClick,
      });
    }

    // add divider only if there are other items, and it's on mobile
    removeItem.withDivider = isMobile && menuItems.length > 0;

    return [...menuItems, removeItem];
  }, [
    handleRemoveClick,
    handleSetPrimaryClick,
    isExpired,
    isMobile,
    isPrimary,
    t,
  ]);
};
