import { useCallback } from 'react';
import { select, focus } from './utils';

type FocusManager = {
  focusById: (elementId: string) => void;
};

export const useFocusManager = (): FocusManager => {
  const focusById = useCallback((elementId: string) => {
    focus(select(elementId));
  }, []);

  return { focusById };
};
