import { createEventHandler } from '@wix/tpa-settings';
import type { DemoView } from '@settings/constants';

enum SettingsEvent {
  DemoViewChanged = 'demoViewChanged',
}

type SettingsEvents = Record<SettingsEvent, string>;

export class SettingsEventManager {
  public static Event = SettingsEvent;
  private eventHandler: ReturnType<typeof createEventHandler<SettingsEvents>>;

  /*
   * @param publicData - Widget settings values
   * */
  constructor(publicData: Object = {}) {
    this.eventHandler = createEventHandler<SettingsEvents>(publicData);
  }

  public onDemoViewChanged(callback: (demoView?: DemoView) => void) {
    this.eventHandler.on(SettingsEventManager.Event.DemoViewChanged, callback);
  }

  public notify(publicData: Object = {}) {
    this.eventHandler.notify(publicData);
  }
}
