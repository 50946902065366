import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  appTitle: SettingsParamType.Text;
  appSubtitle: SettingsParamType.Text;
  noItemsTitle: SettingsParamType.Text;
  noItemsSubtitle: SettingsParamType.Text;
};

/* The 'key' is used for migrating text settings from the old wallet to the new one */
export const textSettingsParams = createSettingsParams<ISettingsParams>({
  appTitle: {
    key: 'titleText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.title'),
  },
  appSubtitle: {
    key: 'subtitleText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.subtitle'),
  },
  noItemsTitle: {
    key: 'noItemsMessage',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.empty-state-page.title'),
  },
  noItemsSubtitle: {
    key: 'noItemsInfo',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.empty-state-page.subtitle'),
  },
});
