import React, { type FC } from 'react';
import type { TextProps as TPATextProps } from 'wix-ui-tpa/cssVars';
import { Text as TPAText, TextPriority } from 'wix-ui-tpa/cssVars';
import { st, classes } from './Text.st.css';

type TextProps = TPATextProps & {
  wiring?: boolean;
};

export const Text: FC<TextProps> = (props) => {
  const {
    wiring,
    children,
    className,
    priority = TextPriority.primary,
    ...rest
  } = props;

  return (
    <TPAText
      {...rest}
      priority={priority}
      className={st(
        classes.root,
        {
          wiring,
          primary: priority === TextPriority.primary,
          secondary: priority === TextPriority.secondary,
        },
        className,
      )}
    >
      {children}
    </TPAText>
  );
};
