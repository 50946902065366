import React, { type FC, type ReactNode, useEffect } from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import type { ControllerProps } from '@appTypes';
import { BiLoggerContext } from '@services/BiLogger';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { AppContext } from '@components/AppContext';
import { ToastContext } from '@components/Toast';
import { ModalContext } from '@components/Modal';
import { App, GeneralError } from './pages';

export type WidgetComponentProps = WidgetProps<ControllerProps> & {
  children: ReactNode;
};

export const WidgetProviders: FC<WidgetComponentProps> = (props) => {
  const {
    isAppLoaded,
    biLogger,
    appParams,
    currentToast,
    toastActions,
    currentModal,
    modalActions,
    host,
    children,
  } = props;
  const { blockScroll, unblockScroll, viewMode, formFactor: deviceType } = host;

  useEffect(() => {
    if (isAppLoaded) {
      biLogger?.reportPageLoaded();
    }
  }, [biLogger, isAppLoaded]);

  return (
    <AppContext.Provider value={{ ...appParams, viewMode, deviceType }}>
      <BiLoggerContext.Provider value={{ biLogger }}>
        <ModalContext.Provider
          value={{ blockScroll, unblockScroll, currentModal, ...modalActions }}
        >
          <ToastContext.Provider value={{ currentToast, ...toastActions }}>
            {children}
          </ToastContext.Provider>
        </ModalContext.Provider>
      </BiLoggerContext.Provider>
    </AppContext.Provider>
  );
};

const Widget: FC<WidgetComponentProps> = ({ hasInitError, ...props }) => (
  <ErrorBoundary fallback={<GeneralError />} forceRender={hasInitError}>
    <WidgetProviders {...props}>
      <App {...props} />
    </WidgetProviders>
  </ErrorBoundary>
);

export default Widget;
