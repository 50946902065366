import { createSelector } from '@reduxjs/toolkit';
import * as paymentMethodSelectors from '@infrastructure/redux/paymentMethod/selectors';
import * as savedPaymentMethodSelectors from '@infrastructure/redux/savedPaymentMethod/selectors';
import type { AppState } from '../rootReducer';

export const getAppError = (state: AppState) => state.app.appError;

export const isAppFullyLoaded = createSelector(
  paymentMethodSelectors.isAlreadyFetchedOnce,
  savedPaymentMethodSelectors.isAlreadyFetchedOnce,
  (paymentMethodFetched, savedPaymentMethodFetched) => {
    return paymentMethodFetched && savedPaymentMethodFetched;
  },
);
