import React, { type FC, useCallback, useEffect } from 'react';
import { Trans, useTranslation, useFedopsLogger } from '@wix/yoshi-flow-editor';
import { Delete } from '@wix/wix-ui-icons-common/on-stage';
import { Interaction } from '@constants';
import {
  useBiLogger,
  BiModalType,
  BiModalCloseTrigger,
} from '@services/BiLogger';
import { getManageMenuId, useFocusManager } from '@services/FocusManager';
import { AlertDialog } from '@components/Modal';

type Props = {
  isSubmitting?: boolean;
  paymentMethodId: string;
  paymentMethodTitle: string;
  onSubmit: (paymentMethodId: string) => void;
  onClose: () => void;
};

const modalType = BiModalType.RemovePaymentMethod;

export const RemovePaymentMethodModal: FC<Props> = (props) => {
  const {
    isSubmitting,
    paymentMethodId,
    paymentMethodTitle,
    onSubmit,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { biLogger } = useBiLogger();
  const { focusById } = useFocusManager();
  const fedops = useFedopsLogger();

  useEffect(() => {
    fedops.interactionEnded(Interaction.LoadRemovalModal);
    biLogger?.reportModalLoaded({
      modalType,
      savedPaymentMethodId: paymentMethodId,
    });
  }, [biLogger, fedops, paymentMethodId]);

  const handleSubmit = useCallback(() => {
    fedops.interactionStarted(Interaction.RemovePaymentMethod);
    onSubmit(paymentMethodId);
    biLogger?.reportModalClosed({
      modalType,
      action: BiModalCloseTrigger.Submit,
      savedPaymentMethodId: paymentMethodId,
    });
  }, [biLogger, fedops, onSubmit, paymentMethodId]);

  const handleClose = useCallback(
    (action: BiModalCloseTrigger) => {
      onClose();
      focusById(getManageMenuId(paymentMethodId));
      biLogger?.reportModalClosed({
        modalType,
        action,
        savedPaymentMethodId: paymentMethodId,
      });
    },
    [biLogger, focusById, onClose, paymentMethodId],
  );

  return (
    <AlertDialog
      isOpen
      isSubmitting={isSubmitting}
      title={t('app.remove-payment-method-modal.title')}
      cancelText={t('app.remove-payment-method-modal.cancel-button.text')}
      submitText={t('app.remove-payment-method-modal.submit-button.text')}
      submitIcon={<Delete aria-hidden="true" />}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <Trans
        i18nKey="app.remove-payment-method-modal.text"
        values={{ paymentMethodTitle }}
        components={{
          br: <br />,
        }}
      />
    </AlertDialog>
  );
};
