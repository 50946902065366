import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
} from '@wix/tpa-settings';

type IStylesParams = {
  badgeBGColor: StyleParamType.Color;
  badgeTextColor: StyleParamType.Color;
};

export const badgeStylesParams = createStylesParams<IStylesParams>({
  badgeTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  badgeBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
});
