export enum Modal {
  AddPaymentMethod = 'ADD_PAYMENT_METHOD',
  ConfirmPaymentMethodRemoval = 'CONFIRM_PAYMENT_METHOD_REMOVAL',
}

export enum Interaction {
  OpenSettingsPanel = 'open-settings-panel',
  MarkAsPrimary = 'mark-pm-as-primary',
  RemovePaymentMethod = 'remove-pm',
  LoadRemovalModal = 'load-remove-pm-modal',
  LoadAddPaymentMethodModal = 'load-add-pm-modal',
  LoadPaymentMethods = 'load-saved-pms',
  LoadRecurringPaymentMethods = 'load-recurring-pms',
  InitializePaymentsWidget = 'init-cashier-payments-widget',
  LoadSitePaymentMethodTypes = 'load-site-pms-type',
}

export enum Experiment {
  EnableErrorHandler = 'specs.payments-checkout.MyWalletEnableErrorHandler',
  UseSitePaymentMethodTypes = 'specs.payments-checkout.UseSitePaymentMethodTypes',
}

export const DEFAULT_PM_TITLE = 'DEFAULT_PM_TITLE';
export const PM_LOGO_TAG = 'logo_large_rectangle_light';
