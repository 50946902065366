import React, { type FC, type ReactNode, useCallback } from 'react';
import { useErrorBoundary, useErrorMonitor } from '@wix/yoshi-flow-editor';
import { UIErrorBoundary } from './UIErrorBoundary';

type Props = {
  children: ReactNode;
  fallback: React.JSX.Element;
  forceRender?: boolean;
};

export const ErrorBoundary: FC<Props> = (props) => {
  const { children, fallback, forceRender } = props;
  // Catches errors from the controller
  const { error } = useErrorBoundary();
  // Catches errors from the components
  const errorMonitor = useErrorMonitor();

  const handleUIError = useCallback(
    (componentError: unknown) => {
      if (componentError instanceof Error) {
        errorMonitor.captureException(componentError);
      }
    },
    [errorMonitor],
  );

  if (forceRender || error) {
    return fallback;
  }

  return (
    <UIErrorBoundary fallback={fallback} onError={handleUIError}>
      {children}
    </UIErrorBoundary>
  );
};
