import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import type { SavedPaymentMethodWithMeta, ExpirationDate } from '@appTypes';
import { getPayPalHolder, getPayPalTitle } from '@utils/payPal';
import {
  getCreditCardHolder,
  getCreditCardExpirationDate,
  getCreditCardTitle,
} from './creditCard';

export const getPaymentMethodTitle = (
  method: SavedPaymentMethodWithMeta,
  t: TFunction,
  experiments?: Experiments,
): string => {
  const { displayName, paymentMethod } = method;

  if (paymentMethod.typeId === PaymentMethod.CreditCard) {
    return getCreditCardTitle(paymentMethod, t, displayName, experiments);
  }

  if (paymentMethod.typeId === PaymentMethod.PayPal) {
    return getPayPalTitle(paymentMethod, t, displayName, experiments);
  }

  return displayName;
};

export const getPaymentMethodHolder = (
  method: SavedPaymentMethodWithMeta,
  t: TFunction,
): string | undefined => {
  const { paymentMethod } = method;

  if (paymentMethod.typeId === PaymentMethod.CreditCard) {
    return getCreditCardHolder(paymentMethod, t);
  }

  if (paymentMethod.typeId === PaymentMethod.PayPal) {
    return getPayPalHolder(paymentMethod, t);
  }
};

export const getExpirationDate = (
  method: SavedPaymentMethodWithMeta,
): ExpirationDate | undefined => {
  const { paymentMethod } = method;

  if (paymentMethod.typeId === PaymentMethod.CreditCard) {
    return getCreditCardExpirationDate(paymentMethod);
  }
};

export const isDateExpired = (expirationDate: ExpirationDate): boolean => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  return expirationDate.year === currentYear
    ? expirationDate.month < currentMonth
    : expirationDate.year < currentYear;
};

export const formatExpirationDate = (
  expirationDate: ExpirationDate,
): string => {
  const paddedExpirationMonth = `0${expirationDate.month}`.slice(-2);

  return `${paddedExpirationMonth}/${expirationDate.year}`;
};

export const sortPaymentMethods = (
  methods: SavedPaymentMethodWithMeta[],
): SavedPaymentMethodWithMeta[] =>
  [...methods].sort((method) => (method.primary ? -1 : 1));
