import React, { useCallback } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Feedback } from '@components/Feedback';
import { Button } from '@components/Button';
import { st, classes } from './GeneralError.st.css';

export const GeneralError = () => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    window?.location.reload();
  }, []);

  return (
    <Feedback
      title={t('app.error-page.technical-issue.title')}
      subtitle={t('app.error-page.technical-issue.description')}
      className={st(classes.root)}
      actions={
        <Button wiringVariant="primary" onClick={handleClick}>
          {t('app.error-page.technical-issue.cta.text')}
        </Button>
      }
    />
  );
};
