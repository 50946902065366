import type {
  ISettingsParam,
  ISettingsContextValue,
  ISettingsValue,
} from '@wix/tpa-settings';

export const getValueOrDefault = <T>(
  settings: ISettingsContextValue,
  param: ISettingsParam<T>,
): ISettingsValue => settings.get(param) || settings.getDefaultValue(param);
